import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static values = {
    taskId: String,
    taskSetId: String,
    parentRecurringTaskId: String,
    status: String,
  };

  update(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const updateUrl = form.dataset.taskListUrlValue;

    fetch(updateUrl, {
      method: "PATCH",
      body: formData,
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        this.element.classList.remove("editing");
        this.element.classList.add("cursor-move");
        this.isEditing = false;
        // Remove mousedown event listener from the input
        this.inputTarget.removeEventListener(
          "mousedown",
          this.handleInputMousedown.bind(this),
        );
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  complete(event) {
    event.preventDefault();
    const updateUrl = event.currentTarget.dataset.taskCompleteUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  uncomplete(event) {
    event.preventDefault();
    const updateUrl = event.currentTarget.dataset.taskCompleteUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  start(event) {
    event.preventDefault();
    console.log("start");
    const updateUrl = event.currentTarget.dataset.taskStartUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  park(event) {
    event.preventDefault();
    console.log("park");
    const updateUrl = event.currentTarget.dataset.taskParkUrlValue;

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => console.error("Error updating task:", error));
  }

  async updateStatus(status) {
    const url = this.taskIdValue ? `/tasks/${this.taskIdValue}` : `/tasks`;

    const params = {
      task: {
        status: status,
        task_set_id: this.taskSetIdValue,
      },
    };

    // Include parent recurring task ID if this is an instance
    if (this.hasParentRecurringTaskIdValue) {
      params.task.parent_recurring_task_id = this.parentRecurringTaskIdValue;
    }

    await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
          .content,
      },
      body: JSON.stringify(params),
    });
  }
}
