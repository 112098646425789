import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["scrollContainer"];

  #boundCheckSync;
  #boundStoreScrollPosition;
  #scrollPosition;

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) {
      return;
    }

    console.log(`[SyncFramesController] Connected`);

    this.#boundCheckSync = this.checkSync.bind(this);
    this.#boundStoreScrollPosition = this.storeScrollPosition.bind(this);

    window.addEventListener("owl-visible", this.#boundCheckSync);

    window.addEventListener(
      "turbo:before-stream-render",
      this.#boundStoreScrollPosition,
    );

    if (localStorage.getItem(`scroll_position_${this.element.id}`)) {
      this.scrollContainerTarget.scrollTop = localStorage.getItem(
        `scroll_position_${this.element.id}`,
      );

      localStorage.removeItem(`scroll_position_${this.element.id}`);
    }
  }

  disconnect() {
    console.log(`[SyncFramesController] Disconnected`);
    window.removeEventListener("owl-visible", this.#boundCheckSync);
    window.removeEventListener(
      "turbo:before-stream-render",
      this.#boundStoreScrollPosition,
    );
  }

  storeScrollPosition(event) {
    if (event === undefined || this.element.id !== event.target.target) return;

    console.log(
      `[SyncFramesController] Storing scroll position for ${this.scrollContainerTarget} to ${this.scrollContainerTarget.scrollTop}`,
    );

    event.preventDefault();

    this.#scrollPosition = this.scrollContainerTarget.scrollTop;

    localStorage.setItem(
      `scroll_position_${this.element.id}`,
      this.#scrollPosition,
    );

    event.detail.newStream.performAction();
  }

  checkSync() {
    console.log(`[SyncFramesController] Checking sync status...`);

    const frameEl = this.element;
    const syncFrameId = frameEl.dataset.frameId;
    const lastUpdatedAt = frameEl.dataset.frameLastUpdatedAt;
    const type = frameEl.dataset.frameType;

    this.storeScrollPosition();

    fetch(`/sync/${type}/${syncFrameId}?last_updated_at=${lastUpdatedAt}`)
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.text();
        }
        return null;
      })
      .then((html) => {
        if (html) {
          console.log(
            `[SyncFramesController] Syncing ${type} for ${syncFrameId}, last_updated_at ${lastUpdatedAt}`,
          );

          Turbo.renderStreamMessage(html);

          console.log(
            `[SyncFramesController] Applying update to ${syncFrameId}`,
          );
        }
        return null;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
