import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
    this.onAfterSubmit = this.onAfterSubmit.bind(this);
  }

  connect() {
    this.#form().addEventListener("turbo:submit-start", this.onBeforeSubmit);
    this.#form().addEventListener("turbo:submit-end", this.onAfterSubmit);
  }

  disconnect() {
    this.#form().removeEventListener("turbo:submit-start", this.onBeforeSubmit);
    this.#form().removeEventListener("turbo:submit-end", this.onAfterSubmit);
  }

  submit(event) {
    event.preventDefault();
    if (this.element.value === "") return;

    this.#form().requestSubmit();
  }

  onBeforeSubmit() {
    if (!this.#submitButton()) return;
    this.#submitButton().disabled = true;
  }

  onAfterSubmit() {
    if (!this.#submitButton()) return;

    this.#submitButton().disabled = false;
  }

  #submitButton() {
    return this.#form().querySelector('button[type*="submit"]');
  }

  #form() {
    return this.element.closest("form");
  }
}
