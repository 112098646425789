import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "recurrenceOptions",
    "intervalInput",
    "weekdaysInput",
    "weekdaysField",
  ];

  toggleRecurrence(event) {
    this.recurrenceOptionsTarget.classList.toggle(
      "hidden",
      !event.target.checked,
    );
  }

  toggleRecurrenceOptions(event) {
    this.intervalInputTarget.classList.toggle(
      "hidden",
      event.target.value !== "every_n_days",
    );
    this.weekdaysInputTarget.classList.toggle(
      "hidden",
      event.target.value !== "weekly_on_days",
    );
  }

  updateWeekdays() {
    const selectedDays = Array.from(
      this.weekdaysInputTarget.querySelectorAll(
        'input[type="checkbox"]:checked',
      ),
    ).map((checkbox) => checkbox.id.replace("task_weekdays_", ""));
    this.weekdaysFieldTarget.value = selectedDays.join(",");
  }
}
