import { Controller } from "@hotwired/stimulus";
import { hideAll } from "tippy.js";
import Flatpickr from "flatpickr";

export const waitForFormSubmissionResult = (formElement) => {
  return new Promise((resolve, reject) => {
    const listenerCallback = (event) => {
      if (event.detail.formSubmission.formElement !== formElement) return;

      document.removeEventListener("turbo:submit-end", listenerCallback);

      if (event.detail.success) {
        resolve(event.detail);
      } else {
        reject(event.detail);
      }
    };

    document.addEventListener("turbo:submit-end", listenerCallback);
  });
};

export default class extends Controller {
  static targets = ["input"];

  connect() {
    // this.flatpickr = Flatpickr(this.inputTarget, {
    //   enableTime: true,
    //   noCalendar: true,
    //   minuteIncrement: 1,
    //   hourIncrement: 1,
    //   dateFormat: "H:i",
    //   time_24hr: true,
    //   //inline: true,
    // });
  }

  submitForm(event) {
    event.preventDefault();

    const promise = waitForFormSubmissionResult(this.element);
    this.element.requestSubmit();

    promise.then(() => {
      this.closeForm();
      const entriesView = document.querySelector(".entries-view");
      const src = entriesView.dataset.refreshSrc;
      entriesView.src = "";
      entriesView.src = src;
    });
  }

  closeForm(event) {
    hideAll({ duration: 0 });
  }
}
