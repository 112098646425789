import { Controller } from "@hotwired/stimulus";
import { hideAll } from "tippy.js";

export default class extends Controller {
  connect() {
    console.log("ModalContentWatcherController connected");

    this.observer = new MutationObserver(this.handleMutation);
    this.observer.observe(this.element, { childList: true, subtree: false });
  }

  disconnect() {
    this.observer.disconnect();
  }

  handleMutation(mutations) {
    console.log(mutations);
    hideAll({ duration: 0 });
  }
}
