import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // Initial resize attempt
    this.resize();

    // Create an observer to watch for visibility changes
    this.observer = new IntersectionObserver(
      this.handleVisibilityChange.bind(this),
      {
        root: null,
        threshold: 0,
      },
    );

    // Start observing the textarea
    this.observer.observe(this.element);
  }

  disconnect() {
    // Clean up observer when controller disconnects
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleVisibilityChange(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.resize();
      }
    });
  }

  resize() {
    requestAnimationFrame(() => {
      const height = this.element.scrollHeight;

      // Set the final height
      this.element.style.height = `${height}px`;
    });
  }
}
